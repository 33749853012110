import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form", "input"];

    connect() {
        this.inputTargets.forEach((input) => {
            input.addEventListener("change", this.fetchForm.bind(this));
            // To catch event change send to data-custom-number-target
            input.parentNode.addEventListener('custom-number:change', this.fetchForm.bind(this))

        });

        const radioBoxes = document.querySelectorAll('.radio-box');

        radioBoxes.forEach((box) => {
            box.addEventListener('radio-box:change', this.fetchForm.bind(this))
        })

        // document.addEventListener('form:change', this.fetchForm.bind(this))
    }

    async fetchForm() {
        const response = await fetch(this.urlWithQueryString(), {
            headers: {
                'Accept': 'text/vnd.turbo-stream.html',
            }
        });
        const html = await response.text();
        Turbo.renderStreamMessage(html);
    }

    urlWithQueryString() {
        return this.addQueryStrings(new URL(window.location.href))
    }

    addQueryStrings(url) {
        const form = new FormData(this.formTarget);
        for (const [name, value] of form.entries()) {
            if (name !== "authenticity_token" && value) {
                if (name.endsWith("[]")) {
                    url.searchParams.append(name, value);
                } else {
                    url.searchParams.set(name, value);

                }
            }
        }

        return url;
    }

    // clickableLine(e) {
    //     let input;
    //     let target;

    //     if(e.target.tagName.toLowerCase() == "td"){
    //         target = e.target.parentNode;
    //         input = target.querySelector("input");
    //     }
    //     else{
    //         target = e.target;
    //         input = target.querySelector("input");
    //     }
        
    //     if(!input.checked){
    //         input.checked = true;
    //         if(target.dataset.refresh == "refresh"){
    //             this.dispatch('change');
    //         }
    //         e.target.parentNode.classList.toggle("active")
    //     }
    //     else{
    //         if(input.type == "checkbox"){
    //             input.checked = false;
    //             if(target.dataset.refresh == "refresh"){
    //                 this.dispatch('change');
    //             }
    //             e.target.parentNode.classList.toggle("active")
    //         }
    //     }
    // }
}